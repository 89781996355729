const projectslist = [
  {
    id: 1,
    name: "Random Joke Generator",
    projecttype: "Joke Generator",
    fullname: "🃏Random Joke Generator",
    img: `${process.env.PUBLIC_URL}/img/2023/joke.png`,
    language: "HTML, CSS, JavaScript",
    description: `Random joke generator built with HTML, CSS, and JavaScript. Fetching jokes from a public API.`,
    className: "pastproject",
    link: "Fetch-Random-Joke",
    year: 2023,
    hash: "slide1",
    website: true,
  },
  {
    id: 2,
    name: "Classic Games",
    projecttype: "🎮 Gaming ",
    fullname: "🎮 6 Classic Games",
    img: `${process.env.PUBLIC_URL}/img/2023/games.png`,
    language: "HTML, CSS, JavaScript, SASS",
    description: `Gaming site built with JS that consists of 6 classical games: Matching Card, Rock Paper Scissors, Tic Tac Toe, Breakout, Whack a Bear, and Snake.`,
    className: "pastproject",
    link: "Multi-Game-Project",
    year: 2023,
    hash: "slide2",
    website: true,
  },
  {
    id: 3,
    name: "JQ's Coffee and Tea ",
    projecttype: "🍃 Ecommerce ",
    fullname: "🍃 Coffee & Tea Ecommerce",
    img: `${process.env.PUBLIC_URL}/img/2023/coffeetea.png`,
    language: "React, MaterialUI, SASS, Bootstrap",
    description: `E-commerce site built with React & MaterialUI using props, maps, routing, and state to connect data and update the UI.`,
    className: "pastproject",
    link: "Drinks_Ecommerce",
    year: 2023,
    hash: "slide3",
    website: true,
  },
  {
    id: 4,
    name: "Restaurant Survey",
    projecttype: "Form Validation",
    fullname: "🍽️Restaurant Survey Form",
    img: `${process.env.PUBLIC_URL}/img/2022/restaurantsurvey.png`,
    language: "HTML, CSS, JavaScript",
    description: `Survey form built with HTML using form elements, input types, and attributes to create a survey form.`,
    className: "pastproject",
    link: "Restaurant-Survey-Form",
    year: 2022,
    hash: "slide4",
    webiste: false,
  },
  {
    id: 5,
    name: "Yoga",
    projecttype: "Documentation",
    fullname: "🧘‍♀️Yoga Documentation",
    img: `${process.env.PUBLIC_URL}/img/2022/yogadocumentation.png`,
    language: "HTML, CSS, JavaScript",
    description: `Using a vertical nav to create a documentation page for yoga.`,
    className: "pastproject",
    link: "Yoga-Documentation",
    year: 2022,
    hash: "slide5",
    webiste: false,
  },
  {
    id: 6,
    name: "Ice Bear",
    projecttype: "Service Page",
    fullname: "🐻‍❄️Ice Bear Cleaning Service",
    img: `${process.env.PUBLIC_URL}/img/2022/icebear.png`,
    language: "HTML, CSS, JavaScript",
    description: `Survey form built with HTML using form elements, input types, and attributes to create a survey form.`,
    className: "pastproject",
    link: "Ice-Bear-Service-Landing-Page",
    year: 2022,
    hash: "slide6",
    webiste: false,
  },
  {
    id: 7,
    name: "Color Flipper",
    projecttype: "Color Generator",
    fullname: "🎨Color Flipper",
    img: `${process.env.PUBLIC_URL}/img/2022/color.png`,
    language: "HTML, CSS, JavaScript",
    description: `Change background color with a click of a button using JavaScript.`,
    className: "pastproject",
    link: "Background-Color-Switcher",
    year: 2022,
    hash: "slide7",
    webiste: false,
  },
  {
    id: 8,
    name: "Chocolate Landing Page",
    projecttype: "Landing Page",
    fullname: "🍫Chocolate Landing Page",
    img: `${process.env.PUBLIC_URL}/img/2022/chocolate.png`,
    language: "HTML, CSS",
    description: `A simple landing page built with HTML, CSS, and Bootstrap library.`,
    className: "pastproject",
    link: "Bootstrap-Chocolate-Product-Landing-Page",
    year: 2022,
    hash: "slide8",
    webiste: false,
  },
  {
    id: 9,
    name: "Travel Photos",
    projecttype: "Photo Gallery",
    fullname: "📸My Travel Photos Gallery",
    img: `${process.env.PUBLIC_URL}/img/2022/travel.png`,
    language: "HTML, CSS",
    description: `Places I've traveled to with photos and descriptions using HTML, CSS, and some JavaScript.`,
    className: "pastproject",
    link: "My-Travel-Photo-Site",
    year: 2022,
    hash: "slide9",
    webiste: false,
  },
  {
    id: 10,
    name: "Alan Turing",
    projecttype: "Tribute Page",
    fullname: "👨‍💻Alan Turing Tribute Page",
    img: `${process.env.PUBLIC_URL}/img/2022/alanturing.png`,
    language: "HTML, CSS",
    description: `Alan Turing tribute page built with HTML using vanilla JavaScript`,
    className: "pastproject",
    link: "Alan-Turing-Tribute-Pages",
    year: 2022,
    hash: "slide10",
    webiste: false,
  },
  {
    id: 1,
    name: "Word Search ",
    projecttype: "📚 Retrieve Public API",
    fullname: "📚 Dictionary Word Search",
    img: `${process.env.PUBLIC_URL}/img/2023/dictionary.png`,
    language: "HTML, CSS, JavaScript",
    description: `Dictionary built with Vanilla JavaScript using a public API and data retrieval methods such as mapping, forEach, fetch, promise, and async-await.`,
    className: "pastproject",
    link: "Fetch-Dictionary",
    year: 2023,
    hash: "slide11",
    website: true,
  },
  {
    id: 12,
    name: "Dessert Quiz",
    projecttype: "Trivia Quiz",
    fullname: "🍰Dessert Quiz",
    img: `${process.env.PUBLIC_URL}/img/2023/dessert.jpg`,
    language: "Python",
    description: `Dessert quiz built where 10 questions are randomly selected from a list of questions.`,
    className: "pastproject",
    link: "Dessert-Quiz",
    year: 2023,
    hash: "slide12",
    website: false,
  },
  {
    id: 13,
    name: "Sudoku Builder",
    projecttype: "🧩 Puzzle Game",
    fullname: "🧩 Sudoku Builder",
    img: `${process.env.PUBLIC_URL}/img/2023/puzzle.jpg`,
    language: "Python",
    description: `Sudoku game built with Python that generates a 9x9 grid with numbers 1-9 in each row, column, and 3x3 grid.`,
    className: "pastproject",
    link: "Sudoku-Builder",
    year: 2023,
    hash: "slide13",
    website: false,
  },
  {
    id: 14,
    name: "Hangman",
    projecttype: "🎲 Word Game",
    fullname: "🎲 Hangman",
    img: `${process.env.PUBLIC_URL}/img/2023/words.jpg`,
    language: "Python",
    description: `Hangman game built with Python that randomly selects a word from a list of words and allows the user to guess the word.`,
    className: "pastproject",
    link: "Hangman",
    year: 2023,
    hash: "slide14",
    website: false,
  },
]

export default projectslist
