const chatresponses = {
    resume: "Please click on the resume button to view her resume.",
    contact: 'Please contact her through Github, LinkedIn, or email located on her page. The best way is through email at xujacque@gmail.com.',
    phone: 'She prefers to be contacted through email. Please email her at xujacque@gmail.com',
    skills: 'Jacqueline uses skills and tools such as React, JavaScript, HTML, CSS, Python, and more.',
    experience: 'Jacqueline has been a self-taught software developer for more than 2 years. She has created projects, you can check them out on her GitHub or Portfolio page.',
    jobs: 'She is currently looking for a job as a software developer. She is open to any opportunities and is willing to learn new technologies.',
    projects: 'You can check out her projects on her GitHub or Portfolio page.',
    education: 'Jacqueline graduated in City College with a degree in Psychology. She has been a self-taught software developer for more than 2 years.',
    hobbies: 'Her hobbies includes swimming, playing games, cooking, silly dancing, cleaning and organizing. She also loves to travel and try all kinds of food! She is interested in pursuing a career in software development because it feels like solving puzzles and she loves puzzles!',
    certificates: "She has a certification in Azure Fundamental and Azure AI.",
    age: "She is in her late 20s.",
    birthday: "Her birthday is on the month of December.",
    language: "Jacqueline speaks English, Cantonese, and conversaitonal Mandarin.",
    college: "Jacqueline graduated from City College of New York.",
    origin: "She is born and raised in New York City.",

    default: [
        "I'm not sure, could you ask another question?",
        "Sorry, I didn't get that. Could you ask another question?",
        "I'm not sure what you're asking. Could you ask another question?",
        "Hmm, I don't have an answer for that. Could you ask another question?",
        "Come again?",
        "I didn't understand that. Could you rephrase?",
        "Can you ask that in a different way?",
        "I'm not certain about that. Could you try asking in a different way?",
        "I don't have an answer for that. Maybe ask something else?",
        "Could you clarify your question?",
        "I'm not sure how to respond to that. Could you ask something else?",
        "I didn't catch that. Could you ask another question?",
        "Could you provide more details?",
        "I'm not sure what you mean. Could you ask another question?",
        "I'm not sure I understand. Could you ask another question?",
        "Could you ask that differently?",
        "I'm not sure what you're asking. Could you clarify?",
        "I don't know the answer to that. Could you ask something else?",
        "Could you be more specific?",
        "I'm not sure about that. Could you ask another question?",
        "I don't have an answer for that. Could you ask another question?",
        "I'm not sure what you mean. Could you rephrase?",
        "Could you ask that in another way?",
        "I'm not sure I understand. Could you clarify?",
        "I don't know the answer to that. Could you ask another question?",
        "Could you provide more information?",
        "I'm not sure what you're asking. Could you ask another question?",
        "I didn't understand that. Could you ask another question?",
        "Could you ask that differently?",
        "I'm not sure what you mean. Could you clarify?",
        "I don't have an answer for that. Could you ask another question?",
        "I'm not sure how to respond to that. Could you ask another question?",
        "Could you be more specific?",
        "I'm not sure about that. Could you ask another question?",
        "I don't know the answer to that. Could you ask another question?",
        "Could you provide more details?",
        "I'm not sure what you're asking. Could you ask another question?",
        "I didn't catch that. Could you ask another question?",
        "Could you ask that in a different way?",
        "I'm not certain about that. Could you try asking in a different way?",
        "I don't have an answer for that. Maybe ask something else?",
        "Could you clarify your question?",
        "I'm not sure how to respond to that. Could you ask something else?",
        "I didn't understand that. Could you rephrase?",
        "Can you ask that in another way?",
        "I'm not sure what you mean. Could you ask another question?",
        "I'm not sure I understand. Could you ask another question?",
        "Could you ask that differently?",
        "I'm not sure what you're asking. Could you clarify?",
        "I don't know the answer to that. Could you ask something else?",
        "Could you be more specific?"
    ]
    
  };
  
  export default chatresponses;