import React from "react"
// import { BrowserRouter as Router, Route, Switch } from "react-router-dom"
// import Navbar from './components/Navbar'
import Introduction from "./components/Introduction"
import Skills from "./components/Skills"
import Projects from "./components/PresentProjects"
// import Resume from './components/Resume'
import Footer from "./components/Footer"
// import { motion, useScroll } from "framer-motion"
import Chatbot from "./components/Chatbot"

export default function App() {
  // const { scrollYProgress } = useScroll();

  return (
    <>
      {/* <motion.div 
    className="progress-bar"
    style={{ scaleX: scrollYProgress }} />   */}
      <Introduction />
      <div className="sp-background">
        <Skills />
        <Projects />
      </div>
      <Footer />
      <Chatbot />
      {/* <Footer title="useScroll"/> */}
      {/* <Navbar /> */}
      {/* <Resume /> */}
    </>
    // <Router>
    //   <Navbar />
    //   <Switch>
    //     <Route exact path="/">
    //       <Introduction />
    //     </Route>
    //     <Route path="/projects">
    //       <Projects />
    //     </Route>
    //     <Route path="/resume">
    //       <Resume />
    //     </Route>
    //   </Switch>
    //   <Footer />
    // </Router>
  )
}
