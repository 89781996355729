import React, { useState } from "react"

const Arrow = () => {
  const [hovered, setHovered] = useState(false)
  const [pointsTop, setPointsTop] = useState("157.6,67.9 121.8,41.3 123.3,40 157.6,65.3 191.9,40 193.7,41.3")
  const [pointsMiddle, setPointsMiddle] = useState("157.6,85.8 120.8,58.7 124.4,56.4 157.6,81.2 191.2,56.4 194.5,58.7")
  const [pointsBottom, setPointsBottom] = useState("157.6,104 120,76.1 125.1,72.8 157.6,96.8 190.4,72.8 195.5,76.1")

  const handleMouseEnter = () => {
    setHovered(true)
    setPointsTop("157.6,85.8 120.8,58.7 124.4,56.4 157.6,81.2 191.2,56.4 194.5,58.7")
    setPointsMiddle("157.6,85.8 120.8,58.7 124.4,56.4 157.6,81.2 191.2,56.4 194.5,58.7")
    setPointsBottom("157.6,85.8 120.8,58.7 124.4,56.4 157.6,81.2 191.2,56.4 194.5,58.7")
  }

  const handleMouseLeave = () => {
    setHovered(false)
    setPointsTop("157.6,67.9 121.8,41.3 123.3,40 157.6,65.3 191.9,40 193.7,41.3")
    setPointsMiddle("157.6,85.8 120.8,58.7 124.4,56.4 157.6,81.2 191.2,56.4 194.5,58.7")
    setPointsBottom("157.6,104 120,76.1 125.1,72.8 157.6,96.8 190.4,72.8 195.5,76.1")
  }

  return (
    <div className="arrow">
      <svg
        id="more-arrows"
        className="more-arrows"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        title="Projects">
        <polygon className={`arrow-top ${hovered ? "merged" : ""}`} points={pointsTop}></polygon>
        <polygon className="arrow-middle" points={pointsMiddle}></polygon>
        <polygon className={`arrow-bottom ${hovered ? "merged" : ""}`} points={pointsBottom}></polygon>
      </svg>
    </div>
  )
}

export default Arrow
