import React from "react"

export default function Skills() {
  return (
    <>
      <div id="skills" className="skills-container">
          <hr></hr>
          <h2 className="title bold centerText flexCenter">Skills/Tools </h2>
          <hr></hr>
          <div className="flexCenter">
            <img
              className="icons"
              src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/python/python-original.svg"
              alt="python"
              width="45"
              height="45"
            />
            <img
              className="icons"
              src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/jupyter/jupyter-original-wordmark.svg"
              alt="jupyter"
              width="45"
              height="45"
            />
            <img
              className="icons"
              src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/react/react-original-wordmark.svg"
              alt="react"
              width="45"
              height="45"
            />
            <img
              className="icons"
              src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/docker/docker-original-wordmark.svg"
              alt="docker"
              width="45"
              height="45"
            />
            <img
              className="icons"
              src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/postman/postman-original.svg"
              alt="postman"
              width="45"
              height="45"
            />
          </div>

          <div className="flexCenter">
            <img
              className="icons"
              src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/javascript/javascript-original.svg"
              alt="javascript"
              width="45"
              height="45"
            />
            <img
              className="icons"
              src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/html5/html5-original-wordmark.svg"
              alt="html5"
              width="45"
              height="45"
            />
            <img
              className="icons"
              src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/css3/css3-original-wordmark.svg"
              alt="css3"
              width="45"
              height="45"
            />
            <img
              className="icons"
              src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/materialui/materialui-original.svg"
              alt="materialui"
              width="45"
              height="45"
            />
            <img
              className="icons"
              src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/bootstrap/bootstrap-original-wordmark.svg"
              alt="bootstrap"
              width="45"
              height="45"
            />
            <img
              className="icons"
              src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/sass/sass-original.svg"
              alt="sass"
              width="45"
              height="45"
            />
            <img
              className="icons"
              src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/nodejs/nodejs-original-wordmark.svg"
              alt="nodejs"
              width="45"
              height="45"
            />
          </div>

          <div className="flexCenter">
            <img
              className="icons"
              src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/git/git-original.svg"
              alt="git"
              width="45"
              height="45"
            />
            <img
              className="icons"
              src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/vscode/vscode-original.svg"
              alt="vscode"
              width="45"
              height="45"
            />
            <img
              className="icons"
              src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/visualstudio/visualstudio-plain.svg"
              alt="visualstudio"
              width="45"
              height="45"
            />
            <img
              className="icons"
              src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/bash/bash-original.svg"
              alt="bash"
              width="45"
              height="45"
            />
            <img
              className="icons"
              src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/npm/npm-original-wordmark.svg"
              alt="npm"
              width="45"
              height="45"
            />
            <img
              className="icons"
              src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/poetry/poetry-original.svg"
              alt="poetry"
              width="45"
              height="45"
            />
          </div>

        <br></br>
        <br></br>
        <br></br>

        <hr></hr>
        <h2 className="title bold centerText flexCenter">Certifications</h2>
        <hr></hr>
        <div className="flexCenter">
          <div className="fullbadge">
            <a
              href="https://learn.microsoft.com/en-us/users/jqjqjq/credentials/2fcc11843f1e892d"
              target="popup"
              rel="noopener noreferrer">
              <img
                className="badges"
                src={`${process.env.PUBLIC_URL}/img/microsoft-certified-fundamentals-badge.svg`}
                alt="microsoft-certified-azure-fundamentals"
                width="100"
                height="100"
              />
              <span className="badgeText">
                <span className="badgeMobile bold">AZ900 - Azure</span>
              </span>
            </a>
          </div>
          <div className="fullbadge">
            <a
              href="https://learn.microsoft.com/api/credentials/share/en-us/jqjqjq/F0534D4E19D12238?sharingId=BB0DACDD4272677D"
              target="popup"
              rel="noopener noreferrer">
              <img
                className="badges"
                src="https://learn.microsoft.com/media/learn/certification/badges/microsoft-certified-fundamentals-badge.svg?branch=main"
                alt="microsoft-certified-azure-ai-fundamentals"
                width="100"
                height="100"
              />
              <span className="badgeText">
                <span className="badgeMobile bold">AI900 - Azure AI</span>
              </span>
            </a>
          </div>

        </div>

      </div>
    </>
  )
}
