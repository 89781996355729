import React from "react"
import Carousel from "./PastProjects.js"

export default function Projects() {
  return (
    <>
      <h1>
        <div className="flower-container">
          <img className="flower" src={`${process.env.PUBLIC_URL}/img/aflower.png`} alt=" Rainbow Flower" />
          <header className="bold">Projects</header>
        </div>
      </h1>

      <div className="transparent-container">
        <h1 className="bold centerText"> 2024 Projects</h1>
          <a className="centerContainer" target="_blank " href="https://huggingface.co/jqjacq">
            <img
              className="huggingface-logo"
              src={`${process.env.PUBLIC_URL}/img/huggingfacelogo-title.png`}
              alt="Hugging Face"></img>
          </a>

        <div className="project-container">

          <div className="models-container">
          <span><h4 className="bold model-title">Multi-Classification Models</h4></span>
            <div className="model-button-container">
              <a href="https://huggingface.co/spaces/jqjacq/Multi-Pet-Classification"
                target="_blank"
                rel="noopener noreferrer">
                <button
                  className="button"
                  title="Model classifies 10 popular common pets in US: cat, dog, rabbit, hamster, parrot, goldfish, turtle, guinea pig, lizard, and snake.">
                  resnet50
                </button>
              </a>
              <a href="https://huggingface.co/jqjacq/Grain-Detection" target="_blank" rel="noopener noreferrer">
                <button
                  className="button"
                  title="Model classifies 4 different type of grains: noodles, rice, couscous, and oatmeal.">
                  resnet18
                </button>
              </a>
            </div>
          </div>

          <br></br>

          <div className="models-container">
          <span><h4 className="bold model-title">Binary Classification Models</h4></span>
            <div className="model-button-container">
              <a
                href="https://huggingface.co/spaces/jqjacq/Phone-Binary-Classification"
                target="_blank"
                rel="noopener noreferrer">
                <button className="button" title="Model classifies whether image is a phone or not.">
                  resnet34
                </button>
              </a>
            </div>
          </div>

        </div>
      </div>
      <Carousel />
    </>
  )
}
