import React from "react"
import Typed from "typed.js"
import Arrow from "../utilities/Arrow"

export default function About() {
  const greeting = React.useRef(null)
  const code = React.useRef(null)
  React.useEffect(() => {
    const typed = new Typed(code.current, {
      strings: ["software developer.", "programmer.", "coder."],
      typeSpeed: 60,
      backDelay: 500,
      loop: true,
    })
    return () => {
      typed.destroy()
    }
  }, [])
  React.useEffect(() => {
    const typed = new Typed(greeting.current, {
      strings: ["Hello.", "¡Hola!", "你好!", "こんにちは!", "안녕하세요!", "Привет!"],
      typeSpeed: 100,
      backDelay: 1000,
      loop: true,
    })
    return () => {
      typed.destroy()
    }
  }, [])

  return (
    <>
      <div className="intro-background">
        <div className="intro-container containerPad">
          <img className="jq" src={`${process.env.PUBLIC_URL}/img/jq.jpg`} alt="Smiling with desserts" />
          <div className="intro w3-animate-opacity">
            <h1>
              <header className="bold centerText  w3-animate-right">
                <span className="intro" ref={greeting} />
                👋
              </header>
            </h1>

            <h2 className="intro name w3-animate-right centerText">
              I'm Jacqueline. 😊
              <span className="intro nickname">
                I go by
                <img className="jqlogo" src={`${process.env.PUBLIC_URL}/img/jqlogo.png`} alt="JQ Logo" />
              </span>
            </h2>
              <h4 className="intro name centerText">
                I'm a self-taught <span className="intro" ref={code} />{" "}
              </h4>
            <div className="intro-description">
              
              <h4 className="context w3-animate-right centerText">
                <br></br>
                <p className="intro"> I'm currently a student with </p>
                <p className="intro"> Carnegie Mellon TechBridge. </p>
                <br></br>
              </h4>
            </div>

            <nav id="nav" className="nav-container centerText w3-animate-right">
              <a href="https://www.github.com/jqjacq" target="popup" rel="noopener noreferrer" alt="Github">
                <i className="fa-brands fa-2x fa-github socialmedia rightMargin"></i>
              </a>
              <a href="https://www.linkedin.com/in/jqjqjq/" target="popup" alt="LinkedIn" rel="noopener noreferrer">
                <i className="fa-brands fa-2x fa-linkedin socialmedia rightMargin"></i>
              </a>
              <a href="mailto:xujacque@gmail.com" alt="Email Address" rel="noopener noreferrer">
                <i className="fa-solid fa-2x fa-envelope socialmedia"></i>
              </a>
              <button className="button resume-button">
                <a href="../Res.pdf" target="popup" rel="noopener noreferrer">
                  Resume
                </a>
              </button>
            </nav>

            <a href="#skills">
              <Arrow />
            </a>
          </div>
        </div>
      </div>
    </>
  )
}
