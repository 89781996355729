// src/Chatbot.js
import React, { useState, useEffect } from 'react';
import '../styles/chatbot.css';
import chatresponses from '../data/chatresponse';

const Chatbot = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const [hoverText, setHoverText] = useState('.');

    //Toggle chat window
  const toggleChat = () => {
    if (!isOpen) {
        setMessages([{ text: 'Hello! What do you want to know about Jacqueline (JQ)?', 
        sender: 'bot' }]);
    }
    setIsOpen(!isOpen);
  };

  const handleInputChange = (e) => {
    setInput(e.target.value);
  };

  const handleSendMessage = () => {
    if (input.trim() === '') return;

    const newMessage = { text: input, sender: 'user' };
    setMessages([...messages, newMessage]);

    const response = getResponse(input);
    setMessages([...messages, newMessage, { text: response, sender: 'bot' }]);

    setInput('');
  };

  const getResponse = (question) => {
    const userQuestion = question.toLowerCase();
    
    const responseKeywords = {
        resume: ['resume', 'res', 'cv', 'bio', 'biography'],
        contact: ['contact'],
        phone: ['phone', 'call', 'cellphone'],
        skills: ['skills', 'tools'],
        experience: ['experience', 'career'],
        jobs: ['jobs', 'experience', 'career'],
        projects: ['project', 'projects', 'portfolio'],
        education: ['education', 'school', 'degree', 'graduated', 'graduation', 'study', 'studies'],
        hobbies: ['hobbies', 'hobby', 'likes', 'like', 'enjoys', 'enjoy', 'passion', 'passions'],
        certificates: ['certificates', 'certifications', 'certificate', 'certification', 'certs'],
        age: ['age', 'year old', 'old'],
        birthday: ['birthday', 'born', 'birth', 'birthdate', 'bday', 'b-day'],
        language: ['language', 'languages', 'speak', 'speaks', 'spoken'],
        college: ['college', 'university', 'school', 'institution', 'academy', 'campus', 'campuses', 'colleges', 'universities', 'schools', 'institutions', 'academies'],
        origin: ['origin', 'from', 'born', 'hometown', 'home', 'city', 'country', 'national'],
      };
    
      for (const [responseKey, keywords] of Object.entries(responseKeywords)) {
        for (const keyword of keywords) {
            if (userQuestion.includes(keyword)) {
              return `${chatresponses[responseKey]}`;
            } 
            if (userQuestion.includes('interest')) {
              return `${chatresponses.hobbies}`;
            }
            if (userQuestion.includes('language')) {
              return `${chatresponses.language}`;
            }
          }
      }
    
      const defaultResponses = chatresponses.default;
      const randomIndex = Math.floor(Math.random() * defaultResponses.length);
    return defaultResponses[randomIndex];
  };

  useEffect(() => {
    let hoverInterval;
    if (hoverText !== '🗨️') {
      hoverInterval = setInterval(() => {
        setHoverText((prev) => {
          if (prev === '.') return '..';
          if (prev === '..') return '...';
          return '.';
        });
      }, 500);
    }
    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
          handleSendMessage();
        }
      };
  
      window.addEventListener('keydown', handleKeyPress);
  
      return () => {
        clearInterval(hoverInterval);
        window.removeEventListener('keydown', handleKeyPress);
      };
    });
//     return () => clearInterval(hoverInterval);
//   }, [hoverText]);

  const handleMouseEnter = () => {
    setHoverText('🗨️');
  };

  const handleMouseLeave = () => {
    setHoverText('.');
  };


  return (
    <div className="chatbot">
    {!isOpen && (
      <button 
        onClick={toggleChat} 
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        className="chatbot-button">
        {hoverText}
      </button>
    )}
      {isOpen && (
        <div className="chat-window">
            <button className="close-button" onClick={toggleChat}>X</button>
          <div className="messages">
            {messages.map((msg, index) => (
              <div key={index} className={`message ${msg.sender}`}>
                {msg.text}
              </div>
            ))}
          </div>
          <div className="input-area">
            <input
              type="text"
              value={input}
              onChange={handleInputChange}
              onKeyDown={(e) => e.key === 'Enter' && handleSendMessage()}
              placeholder="Ask me anything about JQ..."
            />
            <button onClick={handleSendMessage}>Send</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Chatbot;
